import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Box, Section, Container, Columns } from 'react-bulma-components'

function PeopleComponent({ people }) {
  return (
    <Section>
      <Container>
        <Columns className="is-centered">
          <Columns.Column className="is-8 has-text-centered">
            <h1 className="title pb-3">Mobile, end-to-end</h1>
            <p className="is-size-4 pb-3">
              Work with an app development team that care about making an impact
              in their work, taking ownership and being trusted to deliver
              top-notch apps for you and your customers.
            </p>
          </Columns.Column>
        </Columns>
        <Columns className="is-centered">
          <Columns.Column className="is-9">
            <Columns className="is-multiline ">
              {people.edges.map(({ node: person }) => (
                <Columns.Column
                  className="is-3 has-text-centered content"
                  key={person.id}
                >
                  <Link to={`/about/pocketeers/${person.slug}`}>
                    <Box className="pb-0 is-rounded">
                      <GatsbyImage
                        className=""
                        image={person.mainImage.gatsbyImageData}
                        alt={`${person.rol} - ${person.firstName}`}
                      />
                    </Box>
                  </Link>
                  <p className="has-font-weight-bold mt-3 mb-0">
                    {person.firstName}
                  </p>
                  <p className="has-text-grey">{person.role}</p>
                </Columns.Column>
              ))}

              <Columns.Column className="is-3 has-text-centered content">
                <div className="hiring-pocketeer">
                  <div className="add">
                    <br />
                    <br />
                    <br />
                    <Link to="/about/careers" className="has-text-grey-lighter">
                      <i className="fas fa-plus fa-3x" />
                    </Link>
                  </div>
                </div>
              </Columns.Column>
            </Columns>
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  )
}
PeopleComponent.propTypes = {
  people: PropTypes.object,
}

export default PeopleComponent
